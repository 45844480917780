import mongo from "../Assets/mg.svg";
import node from "../Assets/nodee.svg"
import redux from "../Assets/reduxx.svg"
import react from "../Assets/reactt.svg"
import reacti from "../Assets/aii.svg"
import reactj from "../Assets/materialUi.svg"

import next from '../Assets/nxtjs.svg'


const data = {
  properties: [
    { 
        "picture": node,
        "index": 0

    },
    { 
        "picture": mongo ,
        "index": 1
    },
   
    

    { 
        "picture": redux ,
        "index": 2

    },
    { 
        "picture": react ,
        "index": 3

    },
    { 
        "picture": next,
        "index": 4

    },
    {
        "picture": reacti,
        "index": 5
    },
    {
        "picture": reactj,
        "index": 6
    },


    ],
};

export default data 